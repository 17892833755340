<template>
  <div class="main_instructions_container">
    <LogoContainer class="mt-3"></LogoContainer>
    <div class="text-container d-flex align-items-center">
      <div>
        <p>
          <span class="student-plan-label">
            {{ localization('Step 1') }} 
          </span>
        </p>
        <h3 class="title">
          <span class="d-block">
            {{ localization('Learning Connections') }} 
          </span>
          <span class="d-block">
            {{ localization('Inventory (LCI)') }} 
          </span>
        </h3>
        <p class="description">
          {{ localization('The LCI is a questionnaire used to measure the degree to which you use each of four Learning Patterns.') }} 
          <br />
          {{ localization('Completing the LCI is') }} 
          <span class="marks">
            {{ localization('the first step') }} 
          </span> 
          {{ localization('beginning the Let Me Learn Process®.') }} 
        </p>

        <div>
          <div class="row">
            <div class="col-md-4" v-for="(item, i) in cardItems" :key="i">
              <LciCard :item="item"></LciCard>
            </div>
          </div>
        </div>

        <div class="my-3">
          <button
            @click="switchToQuestions()"
            class="btn btn-primary custom-btn rounded-btn lci_btn"
          >
            {{ localization('Start the LCI') }} 
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionsView",
  data() {
    return {
      cardItems: [
        {
          src: require("@/assets/new-theme/icons/clock.png"),
          title: this.localization("Take the time you need"),
          description: this.localization("While there are no right or wrong answers, there are answers that are more accurate to who you are than others.")
        },
        {
          src: require("@/assets/new-theme/icons/check-circle-broken.png"),
          // title: 'Avoid answering "Sometimes"',
          title: this.localization('Think About Your Answers'),
          description: this.localization('There are times when “Sometimes” is appropriate, but please do not use it as an excuse not to decide.')
        },
        {
          src: require("@/assets/new-theme/icons/target-01.png"),
          title: this.localization("Be as accurate and detailed as possible"),
          description: this.localization("Try to be as accurate and detailed as possible in responding to the open-end questions at the end of the questionnaire.")
        }
      ]
    };
  },
  methods: {
    switchToQuestions() {
      this.$router.push("/new-theme/questions");
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .main_instructions_container {
    width: 100%;
    height: 100vh;
    position: relative;
    .text-container {
      margin-top: 80px;

      .student-plan-label {
        background-color: #f6f1fe;
        color: #7f56d9;
        font-size: 0.875rem;
        font-weight: 500;
        border-radius: 15px;
        padding: 5px 10px;
      }
      .title {
        font-size: 48px;
        font-weight: 600;
      }
      .description {
        font-size: 18px;
        margin: 32px 0 64px;
      }
      .marks {
        text-decoration: underline;
        font-weight: 600;
      }
      .lci_btn {
        margin: 50px 0 50px;
        background-color: rgba(127, 86, 217, 1);
        color: #fff;
      }
    }
  }
}
</style>
